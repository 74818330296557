if (window.location.protocol === 'chrome-extension:') {
  var windowAddEventListener = Window.prototype.addEventListener;
  Window.prototype.addEventListener = function(type, listener) {
    if (type === 'unload' || type === 'beforeunload') {
      windowAddEventListener.call(window, 'message', function(event) {
        if (event.origin === window.location.origin && event.data.action === 'suspend') {
          listener.call(window, event);
        }
      });
    } else {
      return windowAddEventListener.apply(window, arguments);
    }
  };
}
